<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Sửa cấu hình OKR</h6>
        </template>
        <div class="content">
          <b-row>
            <b-col>
              <b-form-group label="Tiêu đề (*)">
                <b-form-input v-model="params.title"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mô tả ngắn">
                <b-form-input v-model="params.caption"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Mục tiêu (*)">
                <b-form-input @keydown="numbersOnly($event)" type="number" v-model="params.target_value"></b-form-input>
                <span class="d-block text-right text-primary" v-if="params.target_value">Mục tiêu: {{ numberFormat(parseInt(params.target_value)) }}</span>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Loại dịch vụ (*)">
                <b-form-select :options="services" v-model="params.execute_class"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Trạng thái (*)">
                <b-form-select :options="status" v-model="params.status"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Thời gian bắt đầu (*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="params.start_date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Thời gian kết thúc (*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="params.end_date"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-center">
                <b-button type="button" variant="primary" @click="updateConfig()" ><i class="fas fa-save"></i> Lưu</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "../../../../core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Vue from "vue";

Vue.use(Notifications);

const OkrRepository = RepositoryFactory.get("monitor");

export default {
  mixins: [Common],
  data() {
    return {
      id: null,
      status: [
        { value: "ACTIVATE", text: "Hoạt động" },
        { value: "DEACTIVATE", text: "Không hoạt động" }
      ],
      services: [],
      params: {
        title: null,
        caption: null,
        target_value: 0,
        execute_class: null,
        status: null,
        start_date: null,
        end_date: null,
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cập nhật cấu hình OKR", route: "update" },
    ]);
  },
  methods: {
    async getConfigById() {
      let id = this.$route.query.id;
      this.id = id;
      this.$bus.$emit("show-loading", true);
      let response = await OkrRepository.detailOkr(id);
      this.$bus.$emit("show-loading", false);

      let body = response.data;

      if (body.error_code) {
        this.notifyAlert("warn", body.message);
        return true;
      }

      let responseData = body.data.data;
      let targetValue = responseData.target_value;
      this.params.title = responseData.title;
      this.params.caption = responseData.caption;
      this.params.target_value = parseInt(targetValue);
      this.params.execute_class = responseData.execute_class;
      this.params.status = responseData.status;
      this.params.start_date = responseData.start_date;
      this.params.end_date = responseData.end_date;

      this.notifyAlert("success", body.message);
    },

    async getServices() {
      this.$bus.$emit("show-loading", true);
      this.services = [];
      let response = await OkrRepository.listServiceOkr();

      this.$bus.$emit("show-loading", false);
      let body = response.data.data;
      if (body.error) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      let services = body.data;

      if (services.length > 1) {
        services.splice(0, 1);
      }

      this.services = services;
    },
    async updateConfig() {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.params);
      let response = await OkrRepository.updateOkr(this.id, params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code) {
        let data = body.data;
        if (data.length > 0) {
          let self = this;
          data.forEach(function(item) {
            self.notifyAlert("warn", item[0]);
          })
        } else {
          this.notifyAlert("warn", body.message);
        }

        return true;
      }

      this.notifyAlert("success", body.data.message);
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
  },
  created() {
    this.getServices();
    this.getConfigById();
  },
  watch: {

  },
};
</script>
